// see 'Outputs' tab from CloudFormation stack 'arcapp-downloads'
const cfg = {
  region: "ap-southeast-2",
  userPoolId: "ap-southeast-2_zpJvbRrsH",
  userPoolWebClientId: "9u70tfop2d7hklf0l6u84djod",
  identityPoolId: "ap-southeast-2:f41780ee-5e46-4b45-8aeb-6627bb5c2936"
};
const awsconfig = {
  Auth: {
    region: cfg.region,
    userPoolId: cfg.userPoolId,
    userPoolWebClientId: cfg.userPoolWebClientId,
    identityPoolId: cfg.identityPoolId,
    mandatorySignIn: true
  },
  Storage: {
    AWSS3: {
      region: cfg.region,
      bucket: "data-arcapp-com-au",
      identityPoolId: cfg.identityPoolId
    }
  }
};
export default awsconfig;
