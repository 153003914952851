import React from "react";
import Amplify, { Auth, Storage } from "aws-amplify";
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignOut
} from "@aws-amplify/ui-react";
import "./Custom.css";
import awsconfig from "./awsconfig";
import { RefreshIcon } from "@heroicons/react/solid";
import MemoryStorage from "memorystorage";

Amplify.configure(awsconfig);
Storage.configure({
  customPrefix: {
    public: "downloads/",
    protected: "should-not-exist/",
    private: "should-not-exist/"
  }
});
Auth.configure({
  storage: new MemoryStorage("arc-downloads")
});

function File(props) {
  return (
    <tr>
      <td className="border">
        <a
          className="underline text-left font-mono text-sm"
          href={props.file["uri"]}
          target="_blank"
          rel="noreferrer"
        >
          {props.file["key"]}
        </a>
      </td>
      <td className="border text-right pr-4">{props.file["size"]}</td>
      <td className="border text-right">
        {props.file["lastModified"].toLocaleString("en-AU", {
          timezone: "Australia/Canberra"
        })}
      </td>
    </tr>
  );
}
function Files(props) {
  return (
    <div className="container mx-auto">
      <div className="flex">
        <table className="shadow-lg table-fixed border flex-grow">
          <thead>
            <tr className="">
              <th className="w-2/3 border text-left">Filename</th>
              <th className="w-1/6 border text-right pr-4">Size (in bytes)</th>
              <th className="w-1/6 border text-right">Last Modified (AEST)</th>
            </tr>
          </thead>
          <tbody>
            {props.files.map((item, index) => {
              return <File key={index} file={item} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function Refresh(props) {
  return (
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
      onClick={props.onClick}
    >
      <RefreshIcon className="h-5 w-5" />
    </button>
  );
}
function Nav(props) {
  return (
    <nav className="bg-white shadow dark:bg-gray-800">
      <div className="container">
        <div className="flex-grow">
          <div className="flex justify-end">
            <AmplifySignOut />
          </div>
        </div>
      </div>
    </nav>
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }
  componentDidMount = async () => {
    this.listFiles();
  };
  listFiles = async () => {
    const component = this;
    component.listPublicFiles();
  };
  listPublicFiles = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    await Storage.list("")
      .then(async result => {
        let copied = [].concat(result).filter(file => {
          return this.isAccessible(file.key, groups);
        });
        console.log(copied);
        await this.getObjectUri(copied);
        this.setState({ files: copied });
      })
      .catch(err => console.log(err));
  };
  getObjectUri = async data => {
    let i, obj;
    let uri = [];
    for (i = 0; i < data.length; i++) {
      obj = data[i]["key"];
      await Storage.get(obj)
        .then(result => {
          uri.push(result);
        })
        .catch(err => console.log(err));
      data[i]["uri"] = uri[i];
    }
  };
  isAccessible(key, groups) {
    // does the prefix match one of the user's groups?
    var accessible = false;
    groups.forEach(group => {
      if (key.startsWith(group)) {
        accessible = true;
      }
    });
    return accessible;
  }
  render() {
    let { files } = this.state;
    return (
      <div className="App">
        <AmplifyAuthContainer>
          <AmplifyAuthenticator>
            <AmplifySignIn
              headerText="ARC File Downloads"
              slot="sign-in"
              hideSignUp="true"
            ></AmplifySignIn>
            <Nav />
            <header>
              <Refresh onClick={this.listFiles} />
            </header>
            <Files key="files" files={files} />
          </AmplifyAuthenticator>
        </AmplifyAuthContainer>
      </div>
    );
  }
}

export default App;
